import React, { useEffect } from "react";
import Modal from "react-modal";
import Button from "components/button";
import PopupModalStyleWrapper from "./PopupModal.Styles";
import talkIcon from "./../../../assets/images/icons/talk.png";

const PopupModal = ({ isOpen, onRequestClose }) => {
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 840) {
        onRequestClose();
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [onRequestClose]);

  const handleLogin = () => {
    window.location.href = "/login";
  };

  const handleRegister = () => {
    window.location.href = "/register";
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Deposit Modal"
      style={{
        overlay: modalOverlayStyles,
        content: modalContentStyles,
      }}
    >
      <span style={closeButtonStyles} onClick={onRequestClose}>
        ×
      </span>
      <img
        style={{ width: "100%" }}
        src="https://placehold.co/800x350/png"
        alt=""
      />
      <div style={divContainer}>
        <div style={divButton}>
          <h6>Não estou registrado</h6>
          <Button 
          onClick={handleRegister}
          style={{ width: "150px", backgroundColor: "gray" }}
          >
            Registre-se
          </Button>
        </div>

        <div style={{ backgroundColor: "#151625" }}>
          <div style={separator}></div>
        </div>

        <div style={divButton}>
          <h6>Tenho uma conta</h6>
          <Button 
          onClick={handleLogin}
          style={{ width: "150px", backgroundColor: "red" }}>
            Iniciar Sessão
          </Button>
        </div>
      </div>
      <div style={divTalk}>
        <h6>Fale Conosco</h6>
        <div style={talkIconContainer}>
          <img
            src={talkIcon}
            alt="talkIcon"
            style={{ width: "20px", height: "20px" }}
          />
        </div>
      </div>
      <div style={divFooter}>
        <p style={{ fontSize: "12px", paddingTop: "5px" }}>
          Tem de ter mais de 18 anos para poder participar de qualquer atividade
          de jogo,<br></br> por favor jogue de forma responsável
        </p>
      </div>
    </Modal>
  );
};

export default PopupModal;

const divContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

const divButton = {
  backgroundColor: "#151625",
  width: "100%",
  height: "140px",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const divTalk = {
  backgroundColor: "#272834",
  width: "100%",
  height: "100px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const separator = {
  backgroundColor: "gray",
  width: "2px",
  height: "80px",
  marginTop: "30px",
  borderRadius: "5px",
};

const talkIconContainer = {
  backgroundColor: "#077ed7",
  borderRadius: "15px",
  width: "30px",
  height: "30px",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  cursor: "pointer",
};

const divFooter = {
  backgroundColor: "#3c3d48",
  height: "60px",
  textAlign: "center",
};

const modalOverlayStyles = {
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9999,
};

const modalContentStyles = {
  top: "50%",
  left: "50%",
  right: "auto",
  minWidth: "800px",
  maxWidth: "800px",
  minHeight: "650px",
  maxHeight: "650px",
  bottom: "auto",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "0",
  border: "none",
};

const closeButtonStyles = {
  cursor: "pointer",
  position: "absolute",
  top: "0px",
  right: "15px",
  color: "white",
  fontSize: "25px",
};
