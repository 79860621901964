import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import "../../sections/Games/Game1/Game.css";

import { yoloPlaceBet } from "../../api/DonnoutCalls";

const Wrapper = styled.div`
  position: relative;
`;

const scaleUp = keyframes`
  from {
    transform: scale(0.6);
  }
  to {
    transform: scale(1);
  }
`;

const PopUp = styled.div`
  position: absolute;
  top: 50px;
  left: 0%;
  background-color: #090a1a;
  border-radius: 15px;
  width: 930px;
  height: 68.5vh;
  z-index: 1000;
  transform: ${(props) => (props.onOpen ? "scale(1)" : "scale(0.7)")};
  ${({ onOpen }) =>
    onOpen &&
    css`
      animation: ${scaleUp} 0.2s ease-in-out forwards;
    `}

  header {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    color: white;
    border-radius: 15px 15px 0px 0px;
    background-color: #1a1b2c;
  }

  header h5,
  h3 {
    display: inline;
  }

  main {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    border-radius: 15px;
  }

  main div {
    padding: 10px;
  }
`;

const Tokens = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  div {
    heigth: auto;
  }
`;

const DepositInput = styled.input`
  width: 100%;
  height: 50px; /* Ajustado para um tamanho mais comum para inputs */
  padding: 10px;
  background-color: #1a1b2c;
  color: #fff; /* Cor do texto para garantir visibilidade */
  border: none; /* Remover bordas padrão */
  border-radius: 10px; /* Bordas arredondadas */
`;

const ButtonToSection = styled.button`
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  border-radius: 15px;
  background-color: #1a1b2c;
  color: white;
  font-weight: bold;
`;

export default function PopUpDeposit({ user, activeGame, onClose, onOpen }) {
  const [inputDeposit, setInputDeposit] = useState(0.01);
  const [loadingDeposit, setLoadingDeposit] = useState(false);

  const handleInputChange = (event) => {
    console.log("entrou");
    const newValue = event.target.value; // Corrigido para acessar o valor do input
    const decimalPattern = /^\d*\.?\d{0,2}$/;
    if (!newValue.includes("e")) {
      if (decimalPattern.test(newValue) && newValue <= 10) {
        setInputDeposit(newValue);
      }
    }
  };

  const addToSection = async () => {
    setLoadingDeposit(true);
    if (inputDeposit !== null && inputDeposit !== 0) {
      // Post yoloPlaceBet
      const placeBetBody = {
        name: user.nickname,
        gameId: activeGame,
        address: `${user.id}`,
        amount: inputDeposit,
      };

      // API function that place bet
      try {
        const responsePlaceBet = await yoloPlaceBet(placeBetBody);
        console.log("yoloPlaceBet:", responsePlaceBet);
      } catch (error) {
        console.log(error);
      }
      setLoadingDeposit(false);

      onClose(false);
    }
  };

  return (
    <Wrapper>
      <PopUp onOpen={onOpen}>
        <header>
          <div>
            <h3>Select Entries</h3>
          </div>
          <div>
            <h5
              onClick={() => onClose(false)}
              style={{
                cursor: "pointer",
                fontWeight: "100",
              }}
            >
              X
            </h5>
          </div>
        </header>
        <main>
          <div>
            <span>Tokens</span>
            <Tokens>
              <div>
                <h6>Ethereum</h6>
              </div>
              <div>
                <h6>Cardano</h6>
              </div>
              <div>
                <h6>Polygon</h6>
              </div>
            </Tokens>
          </div>
          <div>
            <h4>Your entry</h4>
            <h6 style={{ color: "#838383" }}>
              The max entry this round is{" "}
              <span style={{ color: "#a3ff12" }}>10 ETH</span>
            </h6>
            <DepositInput value={inputDeposit} onChange={handleInputChange} />
            {loadingDeposit ? (
              <ButtonToSection disabled style={{ background: "#090a1a" }}>
                Carregando...
              </ButtonToSection>
            ) : (
              <ButtonToSection onClick={addToSection}>
                Add to Section
              </ButtonToSection>
            )}
          </div>
        </main>
      </PopUp>
    </Wrapper>
  );
}
