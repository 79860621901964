import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAky8DtydHMewtGVFc4mo-so1xjYyg0G8g",
  authDomain: "clash-fy.firebaseapp.com",
  projectId: "clash-fy",
  storageBucket: "clash-fy.appspot.com",
  messagingSenderId: "1064292555585",
  appId: "1:1064292555585:web:75198380117e8705d41fc0",
  measurementId: "G-185MFWQFP3",
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export const auth = getAuth(app);
