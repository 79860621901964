import React from 'react';
import NavigationStyleWrapper from "./Navigation.style";
import menuShape from "assets/images/project/menu-image.png";

const Navigation = () => {
    const genericMenus = [
        { title: "Roulett Game", url: "/roulette" },
        { title: "Influencers Game", url: "/gamecard" },
        { title: "Cassino ao Vivo", url: "#link3" },
        { title: "Jackpot", url: "#link4" },
    ];

    return (
        <NavigationStyleWrapper>
            <div className="navigation_links">
                {genericMenus.map((menu, i) => (
                    <a key={i} href={menu.url}>{menu.title} <img src={menuShape} alt="shape" /> </a>
                ))}
            </div>
        </NavigationStyleWrapper>
    );
};

export default Navigation;
