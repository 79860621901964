import Pagination from "components/pagination/Pagination";
import TransactionsStyleWrapper from "./Transactions.style.js";
import { useEffect, useState } from "react";

const Transactions = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://us-central1-clash-fy.cloudfunctions.net/getUserTransactions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                userId: "fJc7XYR6rjfHVNYblMvCkZbTVYc2",
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        const transactions = responseData.result.map((item) => ({
          ...item,
          timestamp: new Date(item.timestamp._seconds * 1000).toLocaleString(),
        }));
        setTransactionData(transactions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <TransactionsStyleWrapper>
        <div className="container">
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <div
              style={{
                marginBottom: "1000px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h1 style={{ fontSize: "50px" }}>Carregando...</h1>
              <div
                style={{
                  borderRadius: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                  border: "10px solid #f82552",
                  borderBottomColor: "#FFF",
                  width: "100px",
                  height: "100px",
                  animation: "spin 1s linear infinite",
                  transform: "translate(-50%, -50%)",
                }}
              ></div>
            </div>
            <style>
              {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
            </style>
            <div className="loading-animation"></div>
          </div>
        </div>
      </TransactionsStyleWrapper>
    );
  }

  return (
    <TransactionsStyleWrapper>
      <div className="container">
        <div className="leaderboard_list">
          {transactionData.map((transaction, i) => (
            <ul key={i} className="leaderboard_list_item">
              <li data-title="ID">{transaction.id}</li>
              <li data-title="Descrição">{transaction.description}</li>
              <li data-title="Status">{transaction.status}</li>
              <li data-title="Data/Hora">{transaction.timestamp}</li>
              <li data-title="Tipo">{transaction.type}</li>
              <li data-title="Valor">{transaction.value}</li>
            </ul>
          ))}
        </div>
        <Pagination />
      </div>
    </TransactionsStyleWrapper>
  );
};

export default Transactions;
