import gameIcon1 from "./../../images/bannerGames/400x400.png";
import gameIcon2 from "./../../images/bannerGames/400x400.png";
import gameIcon3 from "./../../images/bannerGames/400x400.png";
import gameIcon4 from "./../../images/bannerGames/400x400.png";
import gameIcon5 from "./../../images/bannerGames/400x400.png";
import gameIcon6 from "./../../images/bannerGames/400x400.png";
import gameIcon7 from "./../../images/bannerGames/400x400.png";
import gameIcon8 from "./../../images/bannerGames/400x400.png";
import gameIcon9 from "./../../images/bannerGames/400x400.png";
import gameIcon10 from "./../../images/bannerGames/400x400.png";
import gameIcon11 from "./../../images/bannerGames/400x400.png";
import gameIcon12 from "./../../images/bannerGames/400x400.png";
import gameIcon13 from "./../../images/bannerGames/400x400.png";
import gameIcon14 from "./../../images/bannerGames/400x400.png";
import gameIcon15 from "./../../images/bannerGames/400x400.png";
import gameIcon16 from "./../../images/bannerGames/400x400.png";
import gameIcon17 from "./../../images/bannerGames/400x400.png";
import gameIcon18 from "./../../images/bannerGames/400x400.png";
import gameIcon19 from "./../../images/bannerGames/400x400.png";
import gameIcon20 from "./../../images/bannerGames/400x400.png";
import gameIcon21 from "./../../images/bannerGames/400x400.png";
import gameIcon22 from "./../../images/bannerGames/400x400.png";
import gameIcon23 from "./../../images/bannerGames/400x400.png";
import gameIcon24 from "./../../images/bannerGames/400x400.png";
import gameIcon25 from "./../../images/bannerGames/400x400.png";
import gameIcon26 from "./../../images/bannerGames/400x400.png";
import gameIcon27 from "./../../images/bannerGames/400x400.png";
import gameIcon28 from "./../../images/bannerGames/400x400.png";
import gameIcon29 from "./../../images/bannerGames/400x400.png";
import gameIcon30 from "./../../images/bannerGames/400x400.png";
import gameIcon31 from "./../../images/bannerGames/400x400.png";
import gameIcon32 from "./../../images/bannerGames/400x400.png";


import coinIcon1 from "assets/images/project/icon-2.png";
import coinIcon2 from "assets/images/project/icon-1.png";
import coinIcon3 from "assets/images/project/icon-3.png";
import coinIcon4 from "assets/images/project/icon-4.png";

const dataAllGames = [
  {
    gameIcon: gameIcon1,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon2,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon3,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon4,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon5,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon6,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon7,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon8,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon9,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon10,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon11,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon12,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon13,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon14,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon15,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon16,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon17,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon18,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon19,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon20,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon21,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon22,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon23,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon24,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon25,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon26,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon27,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon28,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon29,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon30,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon31,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon32,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
];

export default dataAllGames;
