import numberImg1 from "assets/images/icons/participate-image.png"
import numberImg2 from "assets/images/icons/participate-image2.png"
import numberImg3 from "assets/images/icons/participate-image3.png"

const data = [
    {
        icon: numberImg1,
        title: "Faça o Depósito",
        text: "Faça um depósito em Pix e tenha seu saldo disponível instatâneo."
    },
    {
        icon: numberImg2,
        title: "Jogue e Ganhe",
        text: "Participe de jogos competitivos e divertidos enquanto ganha dinheiro."
    },
    {
        icon: numberImg3,
        title: "Receba o Lucro",
        text: "Tenha acesso instantâneo ao seu saldo com o saque mais rápido do Brasil."
    },
]

export default data;