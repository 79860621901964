const data = [
  {
    title: "Taxas",
    value: "7.50",
  },
  {
    title: "Cashback",
    value: "9.50",
  },
  {
    title: "Mining",
    value: "10.00",
  },
  {
    title: "Games",
    value: "12.00",
  },
  {
    title: "Ecossistema",
    value: "16.00",
  },
  {
    title: "Marketing",
    value: "18.00",
  },
  {
    title: "Prêmios",
    value: "30.00",
  },
];

export default data;
