import axios from "axios";

export const yoloLatestGames = async () => {
  const response = await axios.get(
    "https://us-central1-clash-fy.cloudfunctions.net/yoloLatestGames"
  );
  return response.data;
};

export const yoloGameDetails = async (id) => {
  const response = await axios.get(
    `https://us-central1-clash-fy.cloudfunctions.net/yoloGameDetails?gameId=${id}`
  );
  return response.data;
};

export const yoloPlaceBet = async (postdata) => {
  const response = await axios.post(
    "https://us-central1-clash-fy.cloudfunctions.net/yoloPlaceBet",
    postdata
  );
  return response.data;
};
