import React, { useState, useEffect } from "react";
import { Slider, SliderItem } from "components/slider/Slider";
import PageHeaderStyleWrapper from "./PageHeader.style";
import data from "./../../../assets/data/games/dataV1";

const PageHeader = () => {
  const [dots, setDots] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sliderSettings, setSliderSettings] = useState({
    dots: dots,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 750) {
        setDots(false);
      } else {
        setDots(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setSliderSettings((prevSettings) => ({
      ...prevSettings,
      dots: dots,
    }));
  }, [dots]);

  return (
    <PageHeaderStyleWrapper>
      <div className="container">
        <div className="row align-items-center">
          <div style={{ width: "100%" }}>
            <Slider {...sliderSettings}>
              {data.map((slide) => (
                <SliderItem key={slide.id + slide.url}>
                  <a
                    href={slide.url}
                    target="_blank"
                    style={{
                      display: "inline-block",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={
                        windowWidth < 760 ? slide.gameBannerM : slide.gameBanner
                      } // Condicional para imagem
                      alt=""
                      style={{ borderRadius: "20px", cursor: "pointer" }}
                    />
                  </a>
                </SliderItem>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </PageHeaderStyleWrapper>
  );
};

export default PageHeader;
