import RouletteChart from "./RouletteChart";
import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import { yoloGameDetails, yoloLatestGames } from "../../api/DonnoutCalls";

export default function Roullete() {
  const [latestGame, setLatestGame] = useState(null);
  const [gameDetail, setGameDetail] = useState(null);
  const [time, setTime] = useState(null);

  const {
    data: latestGames,
    error: latestGamesError,
    isFetching: isFetchingLatestGames,
  } = useQuery("yoloLatestGames", yoloLatestGames, {
    enabled: true, // or conditionally enable if needed
  });

  const {
    data: gameDetails,
    error: gameDetailsError,
    isFetching: isFetchingGameDetails,
  } = useQuery(
    ["yoloGameDetails", latestGames ? latestGames[0].id : null],
    () => yoloGameDetails(latestGames[0].id),
    {
      enabled: !!latestGames && latestGames.length > 0, // Only fetch when latestGames is available
    }
  );

  useEffect(() => {
    if (latestGames) {
      console.log(latestGames);
      setLatestGame(latestGames);
    }
  }, [latestGames]); // Adicione latestGames como dependência

  useEffect(() => {
    if (gameDetails) {
      console.log(gameDetails);
      console.log({
        timestamp: gameDetails.timestamp,
        endtime: gameDetails.endTime,
      });
      setTime({
        timestamp: gameDetails.timestamp,
        endtime: gameDetails.endTime,
      });
      setGameDetail(gameDetails);
    }
  }, [gameDetails]);

  return (
    <>
      {latestGame && gameDetail ? (
        <RouletteChart counter={time} />
      ) : (
        <p>Loading</p>
      )}
    </>
  );
}
