import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import Confetti from "./Confetti";
import "./index.css";

const data02 = [
  { name: "A", value: 80 },
  { name: "B", value: 45 },
  { name: "C", value: 25 },
];

const Colors = [
  "#FFA500", // Laranja
  "#FF0000", // Vermelho
  "#1E90FF", // Azul
  "#FFFFFF", // Branco
  "#FFFF00", // Amarelo
  "#7FFF00", // Verde claro
  "#800080", // Roxo
  "#006400", // Verde escuro
  "#FFC0CB", // Rosa
  "#00FFFF", // Cyan
  "#F5F5F5", // Branco (mais claro)
  "#808080", // Cinza
];

const timerColors = [
  "#FFF", // Branco
  "#333", // CinzaClaro
];

export default class RouletteChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      remainingTime: null,
      rotationAngle: 0, // Estado que controla o ângulo de rotação
      isSpinning: false, // Controle para a rotação contínua
    };
    this.roundTime = 600;
    this.intervalId = null; // Para armazenar o ID do intervalo
    this.confettiRef = React.createRef();
  }

  // CountDown

  componentDidMount() {
    console.log("Entrou no componentDidMount");
    this.calculateRemainingTime(); // Inicia o cálculo ao montar o componente
    this.startCountdown(); // Inicia o contador
  }

  componentWillUnmount() {
    console.log("Entrou no componentWillUnmount");
    clearInterval(this.intervalId); // Limpa o intervalo ao desmontar o componente
  }

  calculateRemainingTime() {
    const { counter } = this.props;
    const { timestamp, endtime } = counter || {};

    if (
      timestamp &&
      endtime &&
      timestamp._seconds !== undefined &&
      endtime._seconds !== undefined
    ) {
      const currentTime = Math.floor(Date.now() / 1000); // Pega o tempo atual em segundos
      const remainingTime = endtime._seconds - currentTime;

      if (remainingTime > 0 || remainingTime === 0) {
        this.setState({ remainingTime: remainingTime });
      } else {
        setTimeout(() => {
          this.rotateTo(1);
        }, 6000);
      }
    } else {
      console.error("timestamp ou endtime não definidos corretamente.");
    }
  }

  startCountdown() {
    console.log("Entrou no StartCountdown");

    this.intervalId = setInterval(() => {
      this.calculateRemainingTime();

      if (this.state.remainingTime <= 0) {
        clearInterval(this.intervalId);
      }
    }, 1000); // Atualiza a cada segundo
  }

  // Timer CountDown

  rotateTo = (index) => {
    const totalValue = data02.reduce((sum, item) => sum + item.value, 0);
    let startAngle = 0;

    const startAngles = [];
    const endAngles = [];

    data02.forEach((item) => {
      const segmentAngle = (item.value / totalValue) * 360;
      const endAngle = startAngle + segmentAngle;
      startAngles.push(startAngle);
      endAngles.push(endAngle);
      startAngle = endAngle; // Atualiza o ângulo inicial para o próximo segmento
    });

    if (this.state.isSpinning) return;

    this.setState({ isSpinning: true });

    const middleAngle = (startAngles[index] + endAngles[index]) / 2;

    const maxRotation = middleAngle + 10800; // Define o alvo de rotação aleatório entre 1080 e 1440 graus
    let increment = 1; // Incremento de 1 grau
    const speed = 1; // Velocidade de atualização (5ms)

    this.intervalId = setInterval(() => {
      this.setState((prevState) => {
        const progress = (prevState.rotationAngle / maxRotation) * 100;
        // Atualiza o valor do incremento com base no progresso

        // Controlando a animação da rotação
        if (progress >= 100) {
          setTimeout(() => {
            this.confettiRef.current.fire();
            setTimeout(() => {
              this.startCountdown();
            }, 5000);
          }, 800);
        } else if (progress >= 99.5) {
          increment = 0.1;
        } else if (progress >= 94) {
          increment = 0.5;
        } else if (progress >= 98) {
          increment = 0.8;
        } else if (progress >= 87.5) {
          increment = 1;
        } else if (progress >= 82.5) {
          increment = 1.5;
        } else if (progress >= 80) {
          increment = 2;
        } else if (progress >= 30) {
          increment = 3;
        } else if (progress >= 25) {
          increment = 2.5;
        } else if (progress >= 15) {
          increment = 2;
        } else if (progress >= 10) {
          increment = 1.5;
        }

        if (prevState.rotationAngle >= maxRotation) {
          clearInterval(this.intervalId); // Limpa o intervalo quando o ângulo máximo for atingido
          return { isSpinning: false, rotationAngle: prevState.rotationAngle };
        }
        return { rotationAngle: prevState.rotationAngle + increment };
      });
    }, speed); // Ajusta a velocidade de atualização
  };

  // verifyWinner(prevWinner) {
  //   const { winner } = this.props;
  //   // Checando se a prop "winner" mudou e se não é null
  //   if (prevWinner.winner !== winner && winner !== null) {
  //     this.rotateTo(winner);
  //   }
  // }

  // // Método do ciclo de vida que verifica mudanças nas props
  // componentDidUpdate(prevProps) {
  //   this.verifyWinner(prevProps.winner);
  // }

  render() {
    const { rotationAngle, isSpinning, dataAngles } = this.state;
    const { remainingTime } = this.state;
    let data01 = [];
    if (remainingTime <= 0) {
      data01 = [{ name: "Time", value: this.roundTime }];
    } else {
      data01 = [
        { name: "TimeLeft", value: remainingTime },
        { name: "TimePast", value: this.roundTime - remainingTime },
      ];
    }

    // Irei utilizar para mostrar o vencedor
    // const { winner } = this.props;

    return (
      <div className="pie-chart-container">
        <Confetti ref={this.confettiRef} />
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data01}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={215}
              outerRadius={220}
              fill="#fff"
              stroke="none"
              animationDuration={500}
            >
              {data01.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={timerColors[index % timerColors.length]}
                />
              ))}
            </Pie>
            <Pie
              data={data02}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={150}
              outerRadius={205}
              fill="#8884d8"
              className="pie-rotation"
              style={{
                transform: `rotate(${rotationAngle}deg)`, // Aplica a rotação grau a grau
              }}
              stroke="none"
              animationDuration={500}
            >
              {data02.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={Colors[index % Colors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
