// Confetti.js
import React, { useEffect, useRef } from "react";
import confetti from "canvas-confetti";

const Confetti = React.forwardRef(({}, ref) => {
  const fire = () => {
    // Create a canvas element dynamically
    const canvas = document.createElement("canvas");
    canvas.style.position = "absolute";
    canvas.style.top = "-200px";
    canvas.style.left = 0;
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    canvas.style.pointerEvents = "none";
    document.body.appendChild(canvas);

    // Initialize canvas-confetti on the canvas
    const myConfetti = confetti.create(canvas, { resize: true });

    // Launch the confetti animation
    myConfetti({
      particleCount: 150,
      spread: 70,
      origin: { y: 0.6 },
    });

    // Remove the canvas after the animation
    setTimeout(() => {
      document.body.removeChild(canvas);
      //if (onAnimationEnd) onAnimationEnd(); // Notify parent component
    }, 3000); // Duration of the animation
  };

  React.useImperativeHandle(ref, () => ({
    fire,
  }));

  return null;
});

export default Confetti;
