import {
  TbBrandLinkedin,
  TbBrandTwitter,
  TbBrandFacebook,
  TbBrandInstagram,
  TbBrandTiktok,
} from "react-icons/tb";

import avatar1 from "assets/images/team/team-image.png";
import avatar2 from "assets/images/team/team-image2.png";
import avatar3 from "assets/images/team/team-image3.png";
import avatar4 from "assets/images/team/team-image4.png";
import avatar5 from "assets/images/team/team-image5.png";
import avatar6 from "assets/images/team/team-image6.png";
import avatar7 from "assets/images/team/team-image7.png";
import avatar8 from "assets/images/team/team-image8.png";
const data = [
  {
    avatar: avatar1,
    name: "Guga Torres",
    designation: "1.2 milhões",
    socialLinks: [
      {
        icon: <TbBrandInstagram />,
        url: "#",
      },
      {
        icon: <TbBrandTiktok />,
        url: "#",
      },
      {
        icon: <TbBrandFacebook />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatar2,
    name: "Livia Carvalho",
    designation: "500 mil",
    socialLinks: [
      {
        icon: <TbBrandInstagram />,
        url: "#",
      },
      {
        icon: <TbBrandTiktok />,
        url: "#",
      },
      {
        icon: <TbBrandFacebook />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatar3,
    name: "Mariana Luz",
    designation: "2.5 milhões",
    socialLinks: [
      {
        icon: <TbBrandInstagram />,
        url: "#",
      },
      {
        icon: <TbBrandTiktok />,
        url: "#",
      },
      {
        icon: <TbBrandFacebook />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatar4,
    name: "João Tech",
    designation: "800 mil",
    socialLinks: [
      {
        icon: <TbBrandInstagram />,
        url: "#",
      },
      {
        icon: <TbBrandTiktok />,
        url: "#",
      },
      {
        icon: <TbBrandFacebook />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatar5,
    name: "Fernanda Risos",
    designation: "350 mil",
    socialLinks: [
      {
        icon: <TbBrandInstagram />,
        url: "#",
      },
      {
        icon: <TbBrandTiktok />,
        url: "#",
      },
      {
        icon: <TbBrandFacebook />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatar6,
    name: "Renata Arte",
    designation: "150 mil",
    socialLinks: [
      {
        icon: <TbBrandInstagram />,
        url: "#",
      },
      {
        icon: <TbBrandTiktok />,
        url: "#",
      },
      {
        icon: <TbBrandFacebook />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatar7,
    name: "Paulo Chef",
    designation: "900 mil",
    socialLinks: [
      {
        icon: <TbBrandInstagram />,
        url: "#",
      },
      {
        icon: <TbBrandTiktok />,
        url: "#",
      },
      {
        icon: <TbBrandFacebook />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatar8,
    name: "Simone Viagens",
    designation: "3 milhões",
    socialLinks: [
      {
        icon: <TbBrandInstagram />,
        url: "#",
      },
      {
        icon: <TbBrandTiktok />,
        url: "#",
      },
      {
        icon: <TbBrandFacebook />,
        url: "#",
      },
    ],
  },
];

export default data;
