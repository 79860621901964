import styled from "styled-components";

const ProfileStyleWrapper = styled.div`
  background: #090b1a;
  padding-top: 70px;
  padding-bottom: 140px;
  p {
    margin-bottom: 26px;
  }
  .left_content_thumb {
    width: 33vh;
    height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(30, 31, 53, 0.7);
    margin-left: 40%;
    margin-top: 9%;
  }

  .right_content {
    padding-left: 20px;

    p + p {
      margin-bottom: 17px;
    }
  }

  .member_title {
    font-size: 30px;
    line-height: 1.4;
    color: #ffffff;
    margin-bottom: 21px;
    text-transform: uppercase;

    span {
      display: block;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 7px;
    }
  }

  .member_details {
    li {
      display: flex;
      font-size: 16px;
      align-items: center;
      strong {
        font-family: "Russo One", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        width: 150px;
      }

      span {
        font-family: "Inter";
        line-height: 40px;
        color: #ffffff;
      }

      &.social_items {
        a {
          img {
            height: 18px;
            width: 18px;
          }
          &:hover {
            opacity: 0.6;
          }
        }

        a + a {
          margin-left: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .left_content_thumb {
      max-width: auto !important;
      width: 100%;
      margin-bottom: 40px;
    }

    .right_content {
      padding: 0 15px 0 0;
    }
  }

  @media only screen and (max-width: 480px) {
    .member_title {
      font-size: 24px;
      span {
        font-size: 17px;
      }
    }

    .member_details {
      li {
        font-size: 14px;
        strong {
          width: 120px;
        }
      }
    }
  }

  .card {
    display: flex;
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .left-square {
    width: 100px;
    height: 100px;
    background-color: #ffffff;
  }
  
  .right-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .info-field {
    margin-bottom: 10px;
  }
  
  .info-field label {
    font-weight: bold;
  }
  
  .info-field span {
    margin-left: 10px;
  }

.li-span {
  background-color: rgba(42, 41, 61, 1);
  width: 30vh;
  margin-top: 2px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /
}

.li-space {
    margin-left: 10px;
}

.input-style {
    background-color: transparent;
    color: white;
    border: none;
}
  
  
`;

export default ProfileStyleWrapper;
