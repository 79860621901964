import numberImg1 from "assets/images/icons/participate-image.png"
import numberImg2 from "assets/images/icons/participate-image2.png"
import numberImg3 from "assets/images/icons/participate-image3.png"

const data = [
    {
        icon: numberImg1,
        title: "Comece agora",
        text: "Cadastre-se e torne-se um parceiro ClashFy"
    },
    {
        icon: numberImg2,
        title: "Traga pessoas",
        text: "Escolha seu melhor plano e use seu link para trazer jogadores para o ClashFy"
    },
    {
        icon: numberImg3,
        title: "Pagamento",
        text: "Receba seus pagamentos diretamente em sua conta bancária"
    },
]

export default data;