import gameBanner1 from "assets/images/bannerGames/1200x300.png";
import gameBanner2 from "assets/images/bannerGames/1200x300.png";
import gameBanner3 from "assets/images/bannerGames/1200x300.png";
import gameBanner4 from "assets/images/bannerGames/1200x300.png";
import gameBanner5 from "assets/images/bannerGames/1200x300.png";

import gameBannerM1 from "assets/images/bannerGames/800x300.png";
import gameBannerM2 from "assets/images/bannerGames/800x300.png";
import gameBannerM3 from "assets/images/bannerGames/800x300.png";
import gameBannerM4 from "assets/images/bannerGames/800x300.png";
import gameBannerM5 from "assets/images/bannerGames/800x300.png";

import coinIcon1 from "assets/images/project/icon-2.png";
import coinIcon2 from "assets/images/project/icon-1.png";
import coinIcon3 from "assets/images/project/icon-3.png";
import coinIcon4 from "assets/images/project/icon-4.png";
const data = [
  {
    gameBanner: gameBanner1,
    gameBannerM: gameBannerM1,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameBanner: gameBanner2,
    gameBannerM: gameBannerM2,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.youtube.com/",
  },
  {
    gameBanner: gameBanner3,
    gameBannerM: gameBannerM3,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameBanner: gameBanner4,
    gameBannerM: gameBannerM4,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameBanner: gameBanner5,
    gameBannerM: gameBannerM5,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
];

export default data;
