const data = [
  {
    title: "Usuários",
    value: "386000",
  },
  {
    title: "Jogos Realizados",
    value: "486",
  },
  {
    title: "Em Prêmios",
    value: "9",
  },
  {
    title: "Em Cashback",
    value: "18",
  },
];

export default data;
