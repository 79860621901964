import React, { Fragment, useState, useEffect } from "react";
import { useModal } from "utils/ModalContext";
import Layout from "components/layout";
import GlobalStyles from "assets/styles/GlobalStyles";
import WalletModal from "components/modal/walletModal/WalletModal";
import MetamaskModal from "components/modal/metamaskModal/MetamaskModal";
import Header from "sections/Header/v2";
import PageHeader from "sections/HomePage/PageHeader";
import Footer from "sections/Footer/v1";
import HomePage from "sections/HomePage";
import Navigation from "components/SideBar copy/navigation/Navigation";
import FirstLoginPopUp from "components/modal/popupModal/FirstLoginPopUp";

export default function Home() {
  const { walletModalvisibility, metamaskModal } = useModal();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1500);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fixedDivStyle = {
    position: "fixed",
    top: "135px",
    left: "20px",
    padding: "10px",
    backgroundColor: "#1e1f35",
    borderRadius: "10px",
    width: "270px",
    height: "230px",
    color: "black",
    padding: "10px",
    margin: "10px",
    zIndex: 1000,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const headerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1000,
  };

  return (
    <Fragment>
      <Layout>
        <FirstLoginPopUp />
        <GlobalStyles />
        {walletModalvisibility && <WalletModal />}
        {metamaskModal && <MetamaskModal />}
        <div style={headerStyle}>
          <Header />
        </div>
        <div style={{ marginTop: "80px" }}>
          {!isMobile ? (
            <div style={fixedDivStyle}>
              <Navigation />
            </div>
          ) : (
            <div style={{ marginTop: "60px" }}>
              <Navigation />
            </div>
          )}
          <PageHeader />
          <HomePage />
          <Footer />
        </div>
      </Layout>
    </Fragment>
  );
}
