import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "utils/ContextProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "app/App";
import { Toaster } from "react-hot-toast";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <ContextProvider>
      <BrowserRouter>
        <Toaster />
        <App />
      </BrowserRouter>
    </ContextProvider>
  </QueryClientProvider>
);
