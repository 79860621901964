import projectIcon1 from "assets/images/project/ninga-image.png";
import projectIcon2 from "assets/images/project/ninga-2.png";
import projectIcon3 from "assets/images/project/ninga-3.png";
import projectIcon4 from "assets/images/project/ninga-4.png";

import coinIcon1 from "assets/images/project/icon-2.png";
import coinIcon2 from "assets/images/project/icon-1.png";
import coinIcon3 from "assets/images/project/icon-3.png";
import coinIcon4 from "assets/images/project/icon-4.png";
const data = [
  {
    projectIcon: projectIcon1,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    totalRise: "50,000 BRL ( 50% )",
    allocation: "500 BRL Max",
    targetedRise: "100,000 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    progress: "50%",
  },
  {
    projectIcon: projectIcon2,
    title: "Cavalinhos Flash",
    price: "0.50 BRL",
    totalRise: "20,999.70 BRL ( 43% )",
    allocation: "500 BRL Max",
    targetedRise: "500,000 BRL",
    saleEnd: 1717327639,
    coinIcon: coinIcon2,
    progress: "63%",
  },
  {
    projectIcon: projectIcon3,
    title: "Tigrinho Flash",
    price: "1.00 BRL",
    totalRise: "42,999.70 BRL ( 43% )",
    allocation: "500 BRL Max",
    targetedRise: "120,000 BRL",
    saleEnd: 1716827639,
    coinIcon: coinIcon2,
    progress: "83%",
  },
  {
    projectIcon: projectIcon4,
    title: "Foguetinho Clash",
    price: "1.30 BRL",
    totalRise: "22,987.70 BRL ( 43% )",
    allocation: "500 BRL Max",
    targetedRise: "230,000 BRL",
    saleEnd: 1715827639,
    coinIcon: coinIcon2,
    progress: "36%",
  },
];

export default data;
