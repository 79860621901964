import { FaTelegramPlane, FaTwitter, FaDiscord,  FaGlobe} from "react-icons/fa"
import { BsMedium } from "react-icons/bs";

import projectThumb1 from "assets/images/project/project-image.png";
import projectThumb2 from "assets/images/project/project-image2.png";
import projectThumb3 from "assets/images/project/project-image3.png";
import projectThumb4 from "assets/images/project/project-image4.png";
import projectThumb5 from "assets/images/project/project-image5.png";
import projectThumb6 from "assets/images/project/project-image6.png";

import coinIcon1 from "assets/images/project/project-single-image.png";
import coinIcon2 from "assets/images/project/project-single-image2.png";
import coinIcon3 from "assets/images/project/project-single-image3.png";
import coinIcon4 from "assets/images/project/project-single-image4.png";
import coinIcon5 from "assets/images/project/project-single-image5.png";
import coinIcon6 from "assets/images/project/project-single-image6.png";

const data = [
    {
        thumb: projectThumb1,
        title: "Cavalinhos Clash",
        price: "1.00 BUSD",
        saleEnd: "28",
        coinIcon: coinIcon2,
        projectDetails: [
            {
                 title: "Aposta Mínima",
                 text: "1.00 BUSD"
            },
            {
                 title: "Aposta Máxima",
                 text: "900.00 BUSD"
            },
            {
                 title: "Prêmio Apróximado",
                 text: "200,000.00 BUSD"
            },
            {
                 title: "Tipo",
                 text: "Publico"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb2,
        title: "Corrida das Blogueiras",
        price: "1.00 BUSD",
        saleEnd: "43",
        coinIcon: coinIcon2,
        projectDetails: [
            {
                title: "Aposta Mínima",
                text: "1.00 BUSD"
           },
           {
                title: "Aposta Máxima",
                text: "900.00 BUSD"
           },
           {
                title: "Prêmio Apróximado",
                text: "200,000.00 BUSD"
           },
           {
                title: "Tipo",
                text: "Publico"
           }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb3,
        title: "Fazenda dos Blogueiros",
        price: "3.00 BUSD",
        saleEnd: "05",
        coinIcon: coinIcon2,
        projectDetails: [
            {
                title: "Aposta Mínima",
                text: "3.00 BUSD"
           },
           {
                title: "Aposta Máxima",
                text: "300.00 BUSD"
           },
           {
                title: "Prêmio Apróximado",
                text: "100,000.00 BUSD"
           },
           {
                title: "Tipo",
                text: "Vip"
           }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb4,
        title: "Cavalinhos Flash",
        price: "0.10 BUSD",
        saleEnd: "12",
        coinIcon: coinIcon4,
        projectDetails: [
            {
                title: "Aposta Mínima",
                text: "0.10 BUSD"
           },
           {
                title: "Aposta Máxima",
                text: "100.00 BUSD"
           },
           {
                title: "Prêmio Apróximado",
                text: "30,000.00 BUSD"
           },
           {
                title: "Tipo",
                text: "Publico"
           }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb5,
        title: "Foguete Clash",
        price: "0.50 BUSD",
        saleEnd: "14",
        coinIcon: coinIcon2,
        projectDetails: [
            {
                title: "Aposta Mínima",
                text: "0.50 BUSD"
           },
           {
                title: "Aposta Máxima",
                text: "150.00 BUSD"
           },
           {
                title: "Prêmio Apróximado",
                text: "500,000.00 BUSD"
           },
           {
                title: "Tipo",
                text: "Publico"
           }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb6,
        title: "Tigrinho Flash",
        price: "0.10 BUSD",
        saleEnd: "15",
        coinIcon: coinIcon2,
        projectDetails: [
            {
                title: "Aposta Mínima",
                text: "0.50 BUSD"
           },
           {
                title: "Aposta Máxima",
                text: "10.00 BUSD"
           },
           {
                title: "Prêmio Apróximado",
                text: "500.00 BUSD"
           },
           {
                title: "Tipo",
                text: "Publico"
           }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
]

export default data;