import React from "react";
import Button from "components/button";
import ProjectInfoStyleWrapper from "./ProjectInfo.style";

import projectIcon1 from "assets/images/project/ninga-image.png";

const ProjectCard = ({ image, title, description }) => {
  return (
    <div className="game-price-item" style={{ marginTop: "80px" }}>
      <div className="game-price-inner">
        <div className="image-icon">
          <img
            src={image}
            alt="icon"
            style={{ maxWidth: "350px", height: "330px" }}
          />
        </div>
        <div className="text-and-button" style={{ padding: "20px" }}>
          <h2>{title}</h2>
          <p>{description}</p>
          <Button style={{ width: "300px" }}>Saiba mais sobre o plano!</Button>
        </div>
      </div>
    </div>
  );
};

const ProjectInfo = () => {
  return (
    <ProjectInfoStyleWrapper className="live_project_wrapper">
      <ProjectCard
        image={projectIcon1}
        title="Indique e ganhe"
        description="Sempre que alguém que se cadastrou através do seu link se cadastra na plataforma, você ganha sua comissão. Bem simples."
      />
      <ProjectCard
        image={projectIcon1}
        title="Parceiro ClashFy"
        description="Mais do que ganhar comissão pelas indicações, você quer ter a experiência de ser parceiro e participar dos resultados do ClashFy?

        No ClashFy Partner, sempre que uma indicação entra em vigor na plataforma, você ganha sua comissão, mas também tem participação no resultado de suas apostas."
      />
      <ProjectCard
        image={projectIcon1}
        title="Parceiro Influencer"
        description="Já tem uma comunidade de seguidores? Temos um plano especial feito especialmente para você. No Sócio Influencer você ganha uma comissão sempre que um indicado faz um depósito na plataforma e ainda garante uma participação nos lucros da casa."
      />
    </ProjectInfoStyleWrapper>
  );
};

export default ProjectInfo;
