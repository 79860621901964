import Button from "components/button";
import ForgetPasswordStyleWrapper from "./ForgetPassword.style";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";

const ForgetPassword = () => {
  const [recoveryEmail, setRecoveryEmail] = useState("");

  const handleRecoverPassword = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, recoveryEmail)
      .then(() => {
        alert("Email de recuperação de senha enviado com sucesso!");
      })
      .catch((error) => {
        alert("Erro ao enviar o email de recuperação de senha");
      });
  };

  return (
    <ForgetPasswordStyleWrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="auth_form_content">
              <div className="auth_form">
                <h2>RESET PASSWORD</h2>
                <h3>
                  Enviaremos um e-mail com um link para redefinir sua senha
                </h3>
                <form>
                  <div className="row input_field_row">
                    <div className="form-group col-md-12">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Digite seu Email"
                        className="form-control"
                        value={recoveryEmail}
                        onChange={(e) => setRecoveryEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <Button
                    variant="blue"
                    href="#"
                    onClick={handleRecoverPassword}
                  >
                    {" "}
                    Redefinir{" "}
                  </Button>
                </form>
                <h4>
                  Lembra da sua senha ? <a href="/login">Faça Login agora !</a>
                </h4>
              </div>
              <div className="autho_from_shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </ForgetPasswordStyleWrapper>
  );
};

export default ForgetPassword;
