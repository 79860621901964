import Button from "components/button";
import CardHover from "components/cardHover";
import CTAStyleWrapper from "./CTA.style";

const CTA = () => {
  return (
    <CTAStyleWrapper>
      <div className="container">
        <div className="cta-area text-center">
          <h2 className="title">
            Inscreva-se 💌 para
            Novidades & Sorteios
          </h2>
          <div className="dsc">
            Inscreva-se aqui para ficar por dentro de todas as novidades e atualizações
          </div>

          <div className="cta_form_box">
            <input type="email" placeholder="Seu melhor E-mail aqui" />
            <Button variant="mint" md>
              Inscrever-se
            </Button>
          </div>

          <CardHover />
        </div>
      </div>
    </CTAStyleWrapper>
  );
};

export default CTA;
