import HomePageStyleWrapper from "./HomePage.style.js";
import { useEffect, useState } from "react";
import { Slider, SliderItem } from "components/slider/Slider.jsx";
import styled from "styled-components";
import dataRecomed from "assets/data/games/dataV2.js";
import dataAllGames from "assets/data/games/dataV3.js";
import PopupModal from "components/modal/popupModal/PopupModal.jsx";

const HomePage = () => {
  const [dots, setDots] = useState(true);
  const [sliderSettings, setSliderSettings] = useState({
    dots: dots,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 750) {
        setDots(false);
      } else {
        setDots(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setSliderSettings((prevSettings) => ({
      ...prevSettings,
      dots: dots,
    }));
  }, [dots]);

  return (
    <HomePageStyleWrapper>
      <div className="container" style={{ marginBottom: "50px" }}>
        <h1 style={{ marginBottom: "30px" }}>Recomendado</h1>
        <Slider {...sliderSettings}>
          {dataRecomed.map((slide) => (
            <SliderItem key={slide.id + slide.gameIcon}>
              <img
                src={slide.gameIcon}
                alt=""
                style={{
                  borderRadius: "20px",
                  width: "90%",
                  cursor: "pointer",
                }}
                onClick={() => window.open(slide.url, "_blank")}
              />
            </SliderItem>
          ))}
        </Slider>
      </div>
      <div className="container">
        <h1 style={{ marginBottom: "30px" }}>Jogos de Cassino</h1>
        <ImageGrid>
          {dataAllGames.map((image, index) => (
            <img
              key={image.gameIcon + index}
              src={image.gameIcon}
              alt={image.gameIcon}
              style={{
                width: "90%",
                height: "auto",
                borderRadius: "20px",
                cursor: "pointer",
              }}
              onClick={() => window.open(image.url, "_blank")}
            />
          ))}
        </ImageGrid>
      </div>
    </HomePageStyleWrapper>
  );
};

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default HomePage;
