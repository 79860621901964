import React, { useState, useEffect, useRef } from "react";
import "./Game.css";
import Button from "components/button";
import { useMediaQuery } from "react-responsive";
import Roulette from "components/roulette/Roulette";
import { getAuth, signOut } from "firebase/auth";
import { auth } from "firebase-config";

import PopUpDeposit from "components/roulette/PopUpDeposit";

import { yoloLatestGames } from "../../../api/DonnoutCalls";

import RouletteChart from "components/roulette/RouletteChart";

function GameDonnut() {
  const desktopSize = useMediaQuery({ minWidth: 1200 });
  const desktoptSmallSize = useMediaQuery({ minWidth: 1000, maxWidth: 1199 });
  const mobileSize = useMediaQuery({ maxWidth: 1000 });

  const [activeGame, setActiveGame] = useState(null);
  const [players, setPlayers] = useState([]);
  const [playersChance, setPlayersChance] = useState([]);
  const [isPopUpDepositVisible, setIsPopUpDepositVisible] = useState(false);

  const [isConnected, setIsConnected] = useState(false);

  const [user, setUser] = useState(null);
  const [progress, setProgress] = useState(0);

  const [time, setTime] = useState("00:00");
  const [prizePool, setPrizePool] = useState(0);
  const [numberPlayers, setNumberPalyers] = useState(0);
  const [winChance, setWinChance] = useState(0);
  const [yourEntry, setYourEntry] = useState(0);
  const [winner, setWinner] = useState("");

  const handleProgress = (value) => {
    setProgress(value);
    if (value !== "" && !isNaN(value)) {
      const remainingPercentage = 100 - value;
      const seconds = (remainingPercentage / 100) * 600; // 10 minutos em segundos
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
        remainingSeconds
      ).padStart(2, "0")}`;
      setTime(formattedTime);
    } else {
      setTime("");
    }
  };
  const handleNumPlayers = (value) => {
    setNumberPalyers(value);
  };
  const handleWinChance = (value) => {
    console.log(value);
    setWinChance(value);
  };
  const handleYourEntry = (value) => {
    setYourEntry(value);
  };

  // pick jwtUserDetails
  const callApi = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("Usuário não autenticado");
      }

      const token = await user.getIdToken();
      const response = await fetch(
        "https://us-central1-clash-fy.cloudfunctions.net/jwtUserDetails",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao chamar a API");
      }

      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
    }
  };

  // call callApi each 3 seconds
  useEffect(() => {
    callApi();

    if (progress < 100) {
      const interval = setInterval(() => {
        callApi();
      }, 30 * 1000);
      return () => clearInterval(interval);
    }
  }, []);

  // we pick the ID from the game to define the game session
  useEffect(() => {
    const fetchActiveGame = async () => {
      try {
        // Fetch yoloLatestGames
        const responseLatestGames = await yoloLatestGames();
        console.log("yoloLatestGames: ", responseLatestGames);
        setActiveGame(responseLatestGames[0].id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (progress === 100) {
      setTimeout(() => {
        fetchActiveGame();
      }, 20000);
    }
    if (progress < 100) {
      fetchActiveGame();
    }
  }, [progress]);

  useEffect(() => {
    if (players) {
      let totalAmount = 0;
      const length = players.length;

      // Calculando o totalAmount
      for (let index = 0; index < length; index++) {
        totalAmount += players[index].amount;
      }

      // Calculando as chances dos jogadores
      const updatedPlayersChance = players.map((player) => {
        return ((player.amount / totalAmount) * 100).toFixed(2);
      });

      setPlayersChance(updatedPlayersChance);
      setPrizePool(totalAmount.toFixed(2));
    } else {
      setPrizePool(0);
    }
  }, [players]);

  const handleWinner = (value) => {
    console.log(players[value].depositor.name);
    setWinner(value);
    setTimeout(() => {
      setWinner("");
    }, 5000);
  };

  const handlePlayers = (value) => {
    setPlayers(value);
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.opacity = "0.7";
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.opacity = "1";
  };

  const predefinedColors = [
    "#00FFFF", // Cyan
    "#FF0000", // Vermelho
    "#1E90FF", // Azul
    "#FFFFFF", // Branco
    "#FFFF00", // Amarelo
    "#7FFF00", // Verde claro
    "#800080", // Roxo
    "#FFA500", // Laranja
    "#006400", // Verde escuro
    "#FFC0CB", // Rosa
    "#F5F5F5", // Branco (mais claro)
    "#808080", // Cinza
  ];

  let index = 0;
  const getRandomColor = () => {
    const color = predefinedColors[index];
    index = (index + 1) % predefinedColors.length;
    return color;
  };

  const Connect = async () => {
    // Exibir o popup
    if (user) {
      setIsConnected(true);
    }
  };

  const AddSection = async () => {
    setIsPopUpDepositVisible(true);
  };

  const handlePopUpClose = (value) => {
    setIsPopUpDepositVisible(value);
  };

  const PlayerItem = ({ player }) => {
    const randomColor = getRandomColor();
    return (
      <li>
        <div className="player-item">
          <div className="player-info">
            <div className="player-details">
              <p>{player.depositor.name}</p>
            </div>
          </div>
          <div className="player-stats">
            <div className="player-stat-details">
              <p className="player-stat-percentage">
                {playersChance[player.index]}%
              </p>
              <p className="player-stat-value">
                {players[player.index].amount.toFixed(2)}
              </p>
            </div>
            <div
              className="player-stat-bar"
              style={{ backgroundColor: randomColor }}
            ></div>
          </div>
        </div>
      </li>
    );
  };

  return (
    <>
      {mobileSize && (
        <>
          <div className="fixed-bottom">
            <div class="round-info">
              <div class="round-details">
                <h6 class="round-number">Round {activeGame}</h6>
                <h6 class="round-time">{time}</h6>
              </div>
              <nav>
                <ul></ul>
              </nav>
            </div>

            <div className="stats-container-up">
              <div className="stats-container">
                <div className="stats-item">
                  <p className="stat-value">{prizePool}</p>
                  <p className="stat-label">Prize pool</p>
                </div>
                <div div className="stats-item">
                  <p className="stat-value">{yourEntry}</p>
                  <p className="stat-label">Your Entry</p>
                </div>
                <div div className="stats-item">
                  <p className="stat-value">{winChance}%</p>
                  <p className="stat-label">Win Chance</p>
                </div>
              </div>
              <nav>
                <ul></ul>
              </nav>
            </div>

            <Button
              style={{
                backgroundColor: "red",
                alignSelf: "center",
                width: "100%",
                borderRadius: "5px",
              }}
            >
              Enter now
            </Button>
          </div>
        </>
      )}

      <div className="container">
        {/* ----DESKTOP SIZE---- */}

        {desktopSize && (
          <>
            {isPopUpDepositVisible && (
              <PopUpDeposit
                user={user}
                activeGame={activeGame}
                onOpen={isPopUpDepositVisible}
                onClose={handlePopUpClose}
              />
            )}

            <div className="gameDonnut-content">
              <aside className="sidebarLeft">
                <div className="player-container">
                  <div className="player-header">
                    <h6>{numberPlayers} Players</h6>
                    <h6 className="player-watching">245 Watching</h6>
                  </div>
                  <nav>
                    <ul>
                      {players && players.length > 0 ? (
                        players.map((player, index) => (
                          <PlayerItem key={index} player={player} />
                        ))
                      ) : (
                        <></>
                      )}
                    </ul>
                  </nav>
                </div>
              </aside>

              <div className="content">
                <div className="desktop-content-container">
                  <h6 className="desktop-content-current-round">
                    Current Round
                  </h6>

                  <div className="desktop-content-buttons-container">
                    <p
                      className="desktop-content-history"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      History
                    </p>
                    <p
                      className="desktop-content-nav-button"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {"<"}-
                    </p>
                    <p
                      className="desktop-content-nav-button"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      -{">"}
                    </p>
                    <p
                      className="desktop-content-nav-button"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {">"}|
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {/* {winner && players[winner].depositor.name} */}
                  {/* <Roulette
                    user={user}
                    activeGame={activeGame}
                    numPlayers={handleNumPlayers}
                    yourEntry={handleYourEntry}
                    winChance={handleWinChance}
                    playerslist={handlePlayers}
                    progressAtt={handleProgress}
                    winnerRound={handleWinner}
                  /> */}
                  <RouletteChart />
                </div>
              </div>

              <div
                className="sidebarRight"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                }}
              >
                <aside
                  className="sidebarRight1"
                  style={{ height: "30%", width: "100%", marginBottom: "10px" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <h6 style={{ fontSize: "13px" }}>Round 00548</h6>
                      <h6
                        style={{
                          fontSize: "15px",
                          color: "cyan",
                          border: "solid cyan",
                          borderRadius: "5px",
                        }}
                      >
                        {time}
                      </h6>
                    </div>
                    <nav>
                      <ul>
                        <div>
                          <li>
                            <div className="stats-container">
                              <div className="stats-item">
                                <p className="stat-value">{prizePool}</p>
                                <p className="stat-label">Prize pool</p>
                              </div>
                              <div div className="stats-item">
                                <p className="stat-value">{numberPlayers}</p>
                                <p className="stat-label">Players</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="stats-container">
                              <div className="stats-item">
                                <p className="stat-value">{yourEntry}</p>
                                <p className="stat-label">Your Entry</p>
                              </div>
                              <div div className="stats-item">
                                <p className="stat-value">{winChance}%</p>
                                <p className="stat-label">Win Chance</p>
                              </div>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </nav>
                  </div>
                </aside>

                <aside
                  className="sidebarRight2"
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "solid cyan 0.5px",
                    borderRadius: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {isConnected ? (
                    <Button
                      onClick={AddSection}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "100%",
                        color: "black",
                        fontSize: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      Add Section
                    </Button>
                  ) : (
                    <Button
                      onClick={Connect}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "100%",
                        color: "black",
                        fontSize: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      Connect
                    </Button>
                  )}
                  <p style={{ fontSize: "9px" }}>
                    Enter now to have an chance to earn
                  </p>
                </aside>
              </div>
            </div>
          </>
        )}

        {/* ----DESKTOP SMALL SIZE---- */}

        {desktoptSmallSize && (
          <>
            <div style={{ flexDirection: "row", marginTop: "30px" }}>
              <div className="gameDonnut-content">
                <div className="content" style={{ width: "70%" }}>
                  <div className="desktop-content-container">
                    <h6 className="desktop-content-current-round">
                      Current Round: {activeGame}
                    </h6>

                    <div className="desktop-content-buttons-container">
                      <p
                        className="desktop-content-history"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        History
                      </p>
                      <p
                        className="desktop-content-nav-button"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {"<"}-
                      </p>
                      <p
                        className="desktop-content-nav-button"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        -{">"}
                      </p>
                      <p
                        className="desktop-content-nav-button"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {">"}|
                      </p>
                    </div>
                  </div>

                  <div>
                    <Roulette />
                  </div>
                </div>

                <div
                  className="sidebarRight"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "transparent",
                    width: "30%",
                  }}
                >
                  <aside
                    className="sidebarRight1"
                    style={{
                      height: "30%",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <h6 style={{ fontSize: "13px" }}>Round 00548</h6>
                        <h6
                          style={{
                            fontSize: "15px",
                            color: "cyan",
                            border: "solid cyan",
                            borderRadius: "5px",
                          }}
                        >
                          0:54
                        </h6>
                      </div>
                      <nav>
                        <ul>
                          <div>
                            <li>
                              <div className="stats-container">
                                <div className="stats-item">
                                  <p className="stat-value">{prizePool}</p>
                                  <p className="stat-label">Prize pool</p>
                                </div>
                                <div div className="stats-item">
                                  <p className="stat-value">{numberPlayers}</p>
                                  <p className="stat-label">Players</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="stats-container">
                                <div className="stats-item">
                                  <p className="stat-value">{yourEntry}</p>
                                  <p className="stat-label">Your Entry</p>
                                </div>
                                <div div className="stats-item">
                                  <p className="stat-value">{winChance}%</p>
                                  <p className="stat-label">Win Chance</p>
                                </div>
                              </div>
                            </li>
                          </div>
                        </ul>
                      </nav>
                    </div>
                  </aside>

                  <aside
                    className="sidebarRight2"
                    style={{
                      height: "100%",
                      width: "100%",
                      border: "solid cyan 0.5px",
                      borderRadius: "15px",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      onClick={Connect}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "100%",
                        color: "black",
                        fontSize: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      Connect
                    </Button>
                    <p style={{ fontSize: "9px" }}>
                      Enter now to have an chance to earn
                    </p>
                  </aside>
                </div>
              </div>
              <aside className="sidebarLeftSmall">
                <div className="player-container">
                  <div className="player-header">
                    <h6>5 Players</h6>
                    <h6 className="player-watching">245 Watching</h6>
                  </div>
                  <nav>
                    <ul>
                      {players.map((player, index) => (
                        <PlayerItem key={index} player={player} />
                      ))}
                    </ul>
                  </nav>
                </div>
              </aside>
            </div>
          </>
        )}

        {/* ----MOBILE SIZE---- */}

        {mobileSize && (
          <>
            <div className="gameDonnut-content">
              <div
                className="content"
                style={{ width: "100%", marginTop: "20px", padding: "15px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "0",
                  }}
                >
                  <h6>Current Round {activeGame}</h6>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8%",
                      alignItems: "center",
                      width: "40%",
                      justifyContent: "center",
                      marginBottom: "-10px",
                    }}
                  >
                    <p
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        fontSize: "10px",
                      }}
                    >
                      H
                    </p>
                    <p
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        width: "30px",
                        textAlign: "center",
                        fontSize: "10px",
                      }}
                    >
                      {"<"}-
                    </p>
                    <p
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        width: "30px",
                        textAlign: "center",
                        fontSize: "10px",
                      }}
                    >
                      -{">"}
                    </p>
                    <p
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        width: "30px",
                        textAlign: "center",
                        fontSize: "10px",
                      }}
                    >
                      {">"}|
                    </p>
                  </div>
                </div>

                <div>
                  <Roulette />
                </div>
              </div>
            </div>
            <aside className="sidebarLeftSmall" style={{ width: "100%" }}>
              <div className="player-container">
                <div className="player-header">
                  <h6>{numberPlayers} Players</h6>
                  <h6 className="player-watching">245 Watching</h6>
                </div>
                <nav>
                  <ul>
                    {players.map((player, index) => (
                      <PlayerItem key={index} player={player} />
                    ))}
                  </ul>
                </nav>
              </div>
            </aside>
          </>
        )}
      </div>
    </>
  );
}

export default GameDonnut;
