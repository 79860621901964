import ProfileForm from "./ProfileForm/ProfileForm"

import ProfileStyleWrapper from "./Profile.style"

const Profile = () => {
    return (
        <ProfileStyleWrapper>
            <div className="container">
                <div className="row justify-content-center"> 
                    <div className="col-md-6">
                        <ProfileForm />
                    </div>
                </div>
            </div>
        </ProfileStyleWrapper>
    )
}

export default Profile;