import gameIcon1 from "./../../images/bannerGames/400x400.png";
import gameIcon2 from "./../../images/bannerGames/400x400.png";
import gameIcon3 from "./../../images/bannerGames/400x400.png";
import gameIcon4 from "./../../images/bannerGames/400x400.png";
import gameIcon5 from "./../../images/bannerGames/400x400.png";

import coinIcon1 from "assets/images/project/icon-2.png";
import coinIcon2 from "assets/images/project/icon-1.png";
import coinIcon3 from "assets/images/project/icon-3.png";
import coinIcon4 from "assets/images/project/icon-4.png";

const dataRecomended = [
  {
    gameIcon: gameIcon1,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon2,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon3,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon4,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
  {
    gameIcon: gameIcon5,
    title: "Cavalinhos Clash",
    price: "1.00 BRL",
    saleEnd: 1718327639,
    coinIcon: coinIcon2,
    url: "https://www.google.com.br/?hl=pt-BR",
  },
];

export default dataRecomended;
