import { Fragment, useEffect, useState, useCallback } from "react";
import { useModal } from "utils/ModalContext";
import Layout from "components/layout";
import GlobalStyles from "assets/styles/GlobalStyles";
import WalletModal from "components/modal/walletModal/WalletModal";
import MetamaskModal from "components/modal/metamaskModal/MetamaskModal";
import Header from "sections/Header/v2";
import { Unity, useUnityContext } from "react-unity-webgl";
import PageHeader from "sections/ContactPage/PageHeader";
import Contact from "sections/ContactPage";
import Footer from "sections/Footer/v1";
import Button from "components/button";
import DepositModal from "components/modal/depositModal/DepositModal";
import bannerIcon from "./../assets/images/bg/banner-bg.jpg";
import GameDonnut from "sections/Games/Game1/Game";

export default function Game() {
  const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
    loaderUrl: "build/BuildWebGL.loader.js",
    dataUrl: "build/BuildWebGL.data",
    frameworkUrl: "build/BuildWebGL.framework.js",
    codeUrl: "build/BuildWebGL.wasm",
  });

  const [showDepositModal, setShowDepositModal] = useState(false);

  const handleDepositModal = () => {
    setShowDepositModal(!showDepositModal);
  };

  const { walletModalvisibility, metamaskModal } = useModal();
  return (
    <Fragment>
      <Layout>
        <GlobalStyles />
        {walletModalvisibility && <WalletModal />}
        {metamaskModal && <MetamaskModal />}
        <Header />
        {showDepositModal && (
          <DepositModal
            isOpen={showDepositModal}
            onRequestClose={() => setShowDepositModal(false)}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            backgroundImage: `url(${bannerIcon})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* {!isLoaded && (
            <p>Carregando... {Math.round(loadingProgression * 100)}%</p>
          )}
          <Unity
            style={{
              width: "75%",
              justifySelf: "center",
              alignSelf: "center",
              borderRadius: "12px", 
              overflow: "hidden", 
            }}
            unityProvider={unityProvider}
          /> */}
          <GameDonnut></GameDonnut>
          <Button
            style={{ backgroundColor: "red", width: "35vh", marginTop: "50px", marginBottom: "50px"}}
            sm
            variant="red"
            className="connect_btn"
            onClick={() => setShowDepositModal(true)}
          >
            Depositar
          </Button>
        </div>
      </Layout>
      <Footer />
    </Fragment>
  );
}
