import projectThumb1 from "assets/images/project/privius-image.png";
import projectThumb2 from "assets/images/project/privius-image2.png";
import projectThumb3 from "assets/images/project/privius-image3.png";
import projectThumb4 from "assets/images/project/privius-image4.png";
import projectThumb5 from "assets/images/project/privius-image5.png";

import coinIcon1 from "assets/images/project/project-single-image.png";
import coinIcon2 from "assets/images/project/project-single-image2.png";
import coinIcon3 from "assets/images/project/project-single-image3.png";
import coinIcon4 from "assets/images/project/project-single-image4.png";
import coinIcon5 from "assets/images/project/project-single-image5.png";

const data = [
  {
    thumb: projectThumb1,
    title: "Cavalinhos Clash",
    price: "1.00",
    launchedDate: "08",
    totalRised: "100,000",
    progress: "100%",
    coinIcon: coinIcon2,
  },
  {
    thumb: projectThumb2,
    title: "Cavalinhos Flash",
    price: "0.10",
    launchedDate: "03",
    totalRised: "483,000",
    progress: "73%",
    coinIcon: coinIcon2,
  },
  {
    thumb: projectThumb3,
    title: "Foguete Clash",
    price: "0.50",
    launchedDate: "05",
    totalRised: "99,0000",
    progress: "98%",
    coinIcon: coinIcon2,
  },
  {
    thumb: projectThumb4,
    title: "Tigrinho Flash",
    price: "0.10",
    launchedDate: "12",
    totalRised: "82,6000",
    progress: "100%",
    coinIcon: coinIcon2,
  },
  {
    thumb: projectThumb5,
    title: "Corrida das Blogeiras",
    price: "1.00",
    launchedDate: "14",
    totalRised: "38,8000",
    progress: "86%",
    coinIcon: coinIcon2,
  },
];

export default data;
