import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DepositModal from "../depositModal/DepositModal";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Button from "components/button";
import MoneyPattern from "assets/images/bannerGames/DinheiroPattern.png";

// Estilizando o fundo escurecido
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Fundo levemente escurecido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
`;

// Estilizando o popup
const Popup = styled.div`
  position: absolute;
  width: 90%;
  max-width: 600px;
  background: #1e1f35;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

  /* Animação */
  transform: scale(0);
  transition: transform 0.3s ease-in-out;

  /* Quando o popup estiver visível */
  &.visible {
    transform: scale(1);
  }
`;

// Estilizando a imagem no popup
const Image = styled.img`
  width: 100%;
  height: 30vh;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 10px;
`;

export default function FirstLoginPopUp() {
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
      if (user && "isFirstLogin" in user) {
        user.isFirstLogin = false;
        const creationTimeString = user.metadata.creationTime;
        const lastSignInTimeString = user.metadata.lastSignInTime;
        // Verifica se as strings são válidas antes de criar os objetos Date

        if (creationTimeString && lastSignInTimeString) {
          const creationTime = new Date(creationTimeString);
          const creationMinutes = creationTime.getMinutes();
          const now = new Date();
          const nowMinutes = now.getMinutes();

          const isSameOrNextMinute =
            creationMinutes === nowMinutes ||
            creationMinutes + 1 === nowMinutes ||
            creationMinutes === nowMinutes + 1;

          // Verifica se a conta foi criada e o login ocorreu no mesmo minuto
          const isFirstLogin =
            creationTime.getFullYear() === now.getFullYear() &&
            creationTime.getMonth() === now.getMonth() &&
            creationTime.getDate() === now.getDate() &&
            creationTime.getHours() === now.getHours() &&
            isSameOrNextMinute;

          console.log(isFirstLogin);

          if (isFirstLogin) {
            setTimeout(() => {
              setIsFirstLogin(true);
              setTimeout(() => {
                setIsVisible(true);
              }, 100);
            }, 2000);
          }
        } else {
          console.error("Error: Invalid creationTime or lastSignInTime");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  const handleOpenDepositModal = () => {
    setShowDepositModal(true);
    setIsFirstLogin(false);
  };

  const handleClosePopup = () => {
    setIsVisible(false);
    setShowDepositModal(false);
    setIsFirstLogin(false);
  };

  return (
    <>
      {showDepositModal && (
        <DepositModal
          isOpen={showDepositModal}
          onRequestClose={handleClosePopup}
        />
      )}
      {isFirstLogin && (
        <Overlay onClick={handleClosePopup}>
          <Popup
            onClick={(e) => e.stopPropagation()}
            className={isVisible ? "visible" : ""}
          >
            <Image src={MoneyPattern} alt="Welcome" />
            <h2>
              <span style={{ color: "red" }}>BEM-VINDO</span> À NOSSA PLATAFORMA
              SOMENTE <span style={{ color: "red" }}>AGORA</span> DEPOSITE{" "}
              <span style={{ color: "red" }}>R$50</span> E GANHE{" "}
              <span style={{ color: "red" }}>R$100</span>
            </h2>
            <Button
              style={{
                backgroundColor: "red",
                width: "125px",
                height: "50px",
                marginInline: "auto",
              }}
              variant="red"
              className="connect_btn"
              onClick={handleOpenDepositModal}
            >
              Deposito
            </Button>
          </Popup>
        </Overlay>
      )}
    </>
  );
}
