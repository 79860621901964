import styled from "styled-components";

const PopupModalStyleWrapper = styled.div`
  h3 {
    font-size: 22px;
    line-height: 45px !important;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #ffffff;
  }

  p {
    line-height: 45px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 18px;
  }
  a {
    color: #ffffff;
  }

  .btn_wrapper {
    width: 100%;
    height: 60px;
  }

  .kyc_user_form {
    input {
      height: 60px;
      width: 100%;
      padding: 8px 21px;
      border: 2px solid rgba(255, 255, 255, 0.15);
      box-sizing: border-box;
      background: Transparent;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 45px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 23px;
      border-radius: 0;

      &:focus {
        outline: 0 !important;
        box-shadow: none;
      }
    }

    label {
      font-family: "Russo One";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 45px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.7);
    }
  }
 
  .page_header_progressbar {
    display: flex;
    align-items: center;
    position: relative;
    column-gap: 20px;
    span {
      font-family: "Russo One";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      margin-bottom: 0px;
      color: #ffffff;
    }
    .progressbar_wrapper {
      width: 100%;
    }
  }

  @media only screen and (max-width: 991px) {
    .breadcrumb_title {
      font-size: 30px;
    }

    .stake_counter {
      margin-top: 35px;
      justify-content: flex-start;
      h6 {
        span {
          font-size: 22px;
        }
      }
    }

    .page_header_progressbar {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
      row-gap: 14px;
    }
  }

  @media only screen and (max-width: 768px) {
    .breadcrumb_title {
      font-size: 26px;
    }
  }
  @media only screen and (max-width: 540px) {
    .breadcrumb_menu {
      a {
        font-size: 14px;
      }
    }
    .breadcrumb_title {
      font-size: 22px;
    }

    .stake_counter {
      margin-top: 30px;
      h6 {
        span {
          font-size: 18px;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .page_header_progressbar {
      .progressbar_wrapper {
        width: 90%;
      }
    }
  }

`;
// PopupModal.Styles.js

export const modalOverlayStyles = {
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9999,
};

export const modalContentStyles = {
  top: "50%",
  left: "50%",
  right: "auto",
  minWidth: "800px",
  maxWidth: "800px",
  minHeight: "650px",
  maxHeight: "650px",
  bottom: "auto",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "0",
  border: "none",
};

export const closeButtonStyles = {
  cursor: "pointer",
  position: "absolute",
  top: "5px",
  right: "15px",
  color: "white",
  fontSize: "25px",
};

export const imageStyles = {
  width: "100%",
};

export const modalContentContainerStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

export const modalContentSectionStyles = {
  backgroundColor: "#151625",
  width: "100%",
  height: "140px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const dividerStyles = {
  backgroundColor: "gray",
  width: "2px",
  height: "80px",
  borderRadius: "5px",
};

export const footerStyles = {
  backgroundColor: "#272834",
  width: "100%",
  height: "100px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const talkIconContainerStyles = {
  backgroundColor: "#077ed7",
  borderRadius: "15px",
  width: "30px",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

export const talkIconStyles = {
  width: "20px",
  height: "20px",
};


export default PopupModalStyleWrapper;
