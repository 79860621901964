import { Fragment } from "react";
import { useModal } from "utils/ModalContext";
import GlobalStyles from "assets/styles/GlobalStyles"
import Layout from "components/layout";
import WalletModal from "components/modal/walletModal/WalletModal";
import MetamaskModal from "components/modal/metamaskModal/MetamaskModal";
import Header from "sections/Header/v2";
import PageHeader from "sections/ProfileInformations/PageHeader";
import Footer from "sections/Footer/v1";
import Profile from "sections/ProfileInformations/Profile";

export default function ProfileInformations() {
  const { walletModalvisibility, metamaskModal } = useModal();
  return (
    <Fragment>
      <Layout>
        <GlobalStyles />
        {walletModalvisibility && <WalletModal />}
        {metamaskModal && <MetamaskModal />}
        <Header />
        <PageHeader currentPage="Perfil" pageTitle="Informações Pessoais" />
        <Profile />
        <Footer />
      </Layout>
    </Fragment>
  );
}
