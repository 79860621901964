import partnerThumb1 from "assets/images/partner/partner-image.png";
import partnerThumb2 from "assets/images/partner/partner-image2.png";
import partnerThumb3 from "assets/images/partner/partner-image3.png";
import partnerThumb4 from "assets/images/partner/partner-image4.png";
import partnerThumb5 from "assets/images/partner/partner-image5.png";
import partnerThumb6 from "assets/images/partner/partner-image6.png";

const data = [
  partnerThumb1,
  partnerThumb2,
  partnerThumb3,
  partnerThumb4,
  partnerThumb5,
  partnerThumb6,
];

export default data;
