import React, { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import Button from "components/button";
import LoginStyleWrapper from "./Login.style";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../../firebase-config";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Link, useNavigate } from "react-router-dom";
import LoadingOverlay from "components/loading/LoadingOverlay";
import google from "assets/icons/google.png";

const Login = () => {
  const [isChecked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential && userCredential.user) {
        const user = userCredential.user;
        const token = await user.getIdToken();
        setLoggedIn(true);
        navigate("/home");
      } else {
        throw new Error("User data not available");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      console.log("Tentando fazer login com o google");
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      setIsLoading(true);
      if (idToken) {
        localStorage.setItem("jwt", idToken);
      }

      try {
        const requestBody = {
          data: {
            email: result.user.email,
            name: result.user.displayName,
          },
        };

        const user = result.user;

        const functions = getFunctions();
        const googleSignup = httpsCallable(functions, "googleSignup");

        const response = await googleSignup({
          email: user.email,
          name: user.displayName,
        });

        if (response) {
          console.log(requestBody);

          navigate("/home");
        }
      } catch (error) {
        console.log("Usuário já existe");
        navigate("/home");
      }
    } catch (error) {
      console.log("API Google Error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginStyleWrapper>
      {isLoading && <LoadingOverlay />}
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="auth_form_content">
              <div className="auth_form">
                <h2>logIn</h2>
                <h3>
                  Insira seu endereço de e-mail e senha para ter acesso a sua
                  conta
                </h3>
                <form>
                  <div className="row input_field_row">
                    <div className="form-group col-md-12">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Digite seu Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="password">Senha</label>
                      <input
                        type="password"
                        id="password"
                        placeholder="Digite sua senha"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="auth_form_bttom">
                    <div
                      className={`pass_remember_check ${
                        isChecked ? "active" : ""
                      }`}
                    >
                      <label>
                        Lembrar
                        <input
                          type="checkbox"
                          onChange={() => setChecked(!isChecked)}
                        />
                      </label>
                      <span
                        className="checkmark"
                        onClick={() => setChecked(!isChecked)}
                      >
                        {" "}
                        <FiCheck />
                      </span>
                    </div>
                    <a href="/forget-password" className="forget_pass_btn">
                      Esqueci minha senha
                    </a>
                  </div>
                  <Button
                    style={{ marginBottom: "10px" }}
                    variant="blue"
                    type="submit"
                    onClick={handleLogin}
                  >
                    {" "}
                    Entrar{" "}
                  </Button>
                  <Button
                    style={{ backgroundColor: "white", color: "black" }}
                    onClick={handleGoogleLogin}
                  >
                    <img
                      src={google}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "-10px",
                      }}
                    />
                    LOGIN COM GOOGLE
                  </Button>
                </form>
                <h4>
                  Não possui uma conta ?{" "}
                  <a href="/register">Cadastre-se agora !</a>
                </h4>
              </div>
              <div className="autho_from_shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </LoginStyleWrapper>
  );
};

export default Login;
