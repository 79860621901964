import { useState } from "react";
import { FiCheck } from "react-icons/fi";
import Button from "components/button";
import RegisterStyleWrapper from "./Register.style";
import { useLocation } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";

import google from "assets/icons/google.png";

const Register = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referParam = queryParams.get("refer");

  const [name, setName] = useState("");
  const [lastName, setLastname] = useState("");
  const [nickName, setNickname] = useState("");
  const [refer, setRefer] = useState(referParam || "");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const validatePassword = (password) => {
    if (password.length < 8) {
      return false;
    }
    return true;
  };

  const handleSignup = async (e) => {
    if (isChecked) {
      try {
        setLoading(true);

        if (password !== passwordConfirm) {
          toast.error("As senhas estão diferentes.");
          throw new Error("As senhas estão diferentes.");
        }

        if (!validatePassword(password)) {
          toast.error("A senha não atende aos requisitos mínimos.");
          throw new Error("A senha não atende aos requisitos mínimos.");
        }

        try {
          const functions = getFunctions();
          const signup = httpsCallable(functions, "signup");

          const response = await signup({
            email: email,
            password: password,
            nickname: nickName,
            name: name,
            lastName: lastName,
            refer: refer,
            cpf: cpf,
          });
        } catch (error) {
          console.log(error);
          console.log(error.code);
          if (error.message === "auth/email-already-in-use") {
            toast.error("O email já está cadastrado. Tente usar outro email.");
          } else {
            // Trate outros erros, se necessário
            toast.error("Erro ao criar conta");
            console.log("Erro ao criar conta: " + error.message);
          }
          return;
        }
        setLoading(false);
        setShowSuccess(true);
        console.log("Usuário registrado com sucesso!");
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    } else {
      toast.error(`Aceite os termos de uso`, {
        duration: 3000,
        position: "top-center",
      });
    }
  };

  const handleLogin = async () => {
    try {
      const auth = getAuth();

      await signInWithEmailAndPassword(auth, email, password);
      window.location.href = "/home";
    } catch (error) {
      console.error("Erro ao fazer login:", error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const auth = getAuth();
      console.log("Tentando fazer login com o google");
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      setLoading(true);
      if (idToken) {
        localStorage.setItem("jwt", idToken);
      }

      try {
        const requestBody = {
          data: {
            email: result.user.email,
            name: result.user.displayName,
          },
        };

        const user = result.user;

        const functions = getFunctions();
        const googleSignup = httpsCallable(functions, "googleSignup");

        const response = await googleSignup({
          email: user.email,
          name: user.displayName,
        });

        if (response) {
          console.log(requestBody);
        }
      } catch (error) {
        console.log("Usuário já existe");
      }
    } catch (error) {
      console.log("API Google Error");
    } finally {
      setLoading(false);
      setShowSuccess(true);
    }
  };

  const handleErroClick = () => {
    window.location.href = "/";
  };

  return (
    <RegisterStyleWrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="auth_form_content">
              <div className="auth_form">
                {error && (
                  <div
                    style={{
                      marginTop: "130px",
                      marginBottom: "110px",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h2>Tente novamente mais tarde!</h2>
                    <Button
                      style={{
                        backgroundColor: "red",
                        width: "50%",
                        marginBottom: "20px",
                      }}
                      onClick={handleErroClick}
                    >
                      Voltar
                    </Button>
                  </div>
                )}

                {loading && (
                  <div
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    <div
                      style={{
                        marginBottom: "150px",
                        marginTop: "150px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h1 style={{ fontSize: "50px" }}>Carregando...</h1>
                      <div
                        style={{
                          border: "8px solid #f3f3f3",
                          borderTop: "8px solid #3498db",
                          borderRadius: "50%",
                          width: "100px",
                          height: "100px",
                          animation: "spin 1s linear infinite",
                          transform: "translate(-50%, -50%)",
                        }}
                      ></div>
                    </div>
                    <style>
                      {`
                        @keyframes spin {
                          0% { transform: rotate(0deg); }
                          100% { transform: rotate(360deg); }
                        }
                        `}
                    </style>
                    <div className="loading-animation"></div>
                  </div>
                )}

                {showSuccess && (
                  <div
                    style={{
                      marginTop: "130px",
                      marginBottom: "130px",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h2>Cadastro realizado com sucesso!</h2>
                    <Button
                      style={{ backgroundColor: "red", width: "50%" }}
                      onClick={handleLogin}
                    >
                      Continuar
                    </Button>
                  </div>
                )}

                {!showSuccess && !loading && !error && (
                  <form>
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        marginBottom: "2rem",
                      }}
                      onClick={handleGoogleLogin}
                    >
                      <img
                        src={google}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "-10px",
                        }}
                      />
                      CRIAR CONTA COM GOOGLE
                    </Button>
                    <h2>Cria com email e senha</h2>
                    <h3>
                      Insira os dados necessários para criar sua conta ClashFy!
                    </h3>
                    <div className="form-group col-md-12">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Digite seu Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="row input_field_row">
                      <div className="form-group col-md-12">
                        <label htmlFor="fullName">Nome</label>
                        <input
                          type="text"
                          id="fullName"
                          placeholder="Digite seu nome completo"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="fullName">Sobrenome</label>
                        <input
                          type="text"
                          id="fullName"
                          placeholder="Digite seu nome completo"
                          className="form-control"
                          value={lastName}
                          onChange={(e) => setLastname(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="nickName">Apelido</label>
                        <input
                          type="text"
                          id="nickName"
                          placeholder="Digite seu apelido"
                          className="form-control"
                          value={nickName}
                          onChange={(e) => setNickname(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="cpf">CPF</label>
                        <input
                          type="text"
                          id="cpf"
                          placeholder="Digite seu CPF"
                          className="form-control"
                          value={cpf}
                          onChange={(e) => setCpf(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="password">Senha</label>
                        <input
                          type="password"
                          id="password"
                          placeholder="Digite sua senha"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      {password && !validatePassword(password) && (
                        <div className="form-group col-md-12">
                          <h6
                            style={{
                              color: "white",
                              fontSize: "12px",
                              marginTop: "-20px",
                            }}
                          >
                            A senha deve ter pelo menos 8 caracteres.
                          </h6>
                        </div>
                      )}
                      <div className="form-group col-md-12">
                        <label htmlFor="passwordConfirm">
                          Confirme sua senha
                        </label>
                        <input
                          type="password"
                          id="passwordConfirm"
                          placeholder="Digite sua senha novamente"
                          className="form-control"
                          value={passwordConfirm}
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                        />
                      </div>
                      {passwordConfirm.length > 0 &&
                        password !== passwordConfirm && (
                          <div className="form-group col-md-12">
                            <h6
                              style={{
                                color: "white",
                                fontSize: "12px",
                                marginTop: "-20px",
                              }}
                            >
                              As senhas não coincidem.
                            </h6>
                          </div>
                        )}
                      <div className="form-group col-md-12">
                        <label htmlFor="refer">Código de Indicação</label>
                        <input
                          type="text"
                          id="refer"
                          placeholder=""
                          className="form-control"
                          value={refer}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth_form_bttom">
                      <div
                        className={`pass_remember_check ${
                          isChecked ? "active" : ""
                        }`}
                      >
                        <label>
                          Eu aceito os
                          <a href="#">Termos e Condições</a>e
                          <a href="#">Politica de Privacidade</a>
                          <input
                            type="checkbox"
                            onChange={() => setChecked(!isChecked)}
                          />
                        </label>
                        <span
                          className="checkmark"
                          onClick={() => setChecked(!isChecked)}
                        >
                          {" "}
                          <FiCheck />
                        </span>
                      </div>
                    </div>
                    <Button
                      variant="blue"
                      onClick={(e) => {
                        handleSignup(e);
                      }}
                    >
                      {" "}
                      Cadastrar{" "}
                    </Button>
                    <h4>
                      Ja possui uma conta ?{" "}
                      <a href="/login">Faça Login agora !</a>
                    </h4>
                  </form>
                )}
              </div>
              <div className="autho_from_shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </RegisterStyleWrapper>
  );
};

export default Register;
