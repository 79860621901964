import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Button from "components/button";
import styled from "styled-components";

const CookiesModal = ({ isOpen, onRequestClose }) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Cookies Modal"
      style={{
        overlay: modalOverlayStyles,
        content: modalContentStyles,
      }}
    >
      <Container>
        <TextContainer>
          <Paragraph>
            Utilizamos cookies para lhe podermos fornecer a melhor experiência
            de utilização possível. Ao aceder aos serviços da Clashfy, aceita a
            utilização de cookies. Para mais informação, por favor leia a nossa{" "}
            <PrivacyPolicy>Politíca de privacidade</PrivacyPolicy>.
          </Paragraph>
        </TextContainer>
        <ButtonContainer>
          <Button onClick={onRequestClose} style={closeButtonStyle}>
            Fechar
          </Button>
          <Button onClick={onRequestClose} style={okButtonStyle}>OK</Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default CookiesModal;

const modalOverlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 80,
  bottom: 30,
  backgroundColor: "transparent",
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',  
};

const modalContentStyles = {
  position: 'relative',
  width: window.innerWidth < 600 ? "100%" : "70%",
  minHeight: "100px",
  maxHeight: "200px",
  borderRadius: "10px",
  backgroundColor: "#272834",
  padding: "0",
  border: "none",
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",  
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  @media (max-width: 500px) {
    width: 100%;
    justify-content: center;
  }
`;

const TextContainer = styled.div`
  width: 80%;
  max-height: 100px; 
  overflow-y: auto; 
`;

const Paragraph = styled.p`
  font-size: 13px;
`;

const PrivacyPolicy = styled.span`
  color: cyan;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 20%;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    width: 100%;
    justify-content: center;
  }
`;

const closeButtonStyle = {
  minWidth: "80px",
  backgroundColor: "gray",
  fontSize: "12px",
  borderRadius: "10px",
};

const okButtonStyle = {
  minWidth: "80px",
  backgroundColor: "green",
  fontSize: "12px",
  borderRadius: "10px",
};
