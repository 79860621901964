import React, { Fragment, useState, useEffect, useRef } from "react";
import GlobalStyles from "assets/styles/GlobalStyles";
import Button from "components/button";
import Layout from "components/layout";
import Footer from "sections/Footer/v1";
import bannerIcon from "./../assets/images/bg/banner-bg.jpg";
import bannerIcon2 from "./../assets/images/bg/project-bg.jpg";
import MuxPlayer from "@mux/mux-player-react";
import wppIcon1 from "./../assets/images/icons/wpp1.png";
import wppIcon2 from "./../assets/images/icons/wpp2.png";
import clashIcon from "./../assets/images/favicon.png";

const bannerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "black",
  padding: "20px",
  height: "100vh",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const buttonContainerStyles = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#0c0c1c",
};

const buttonStyles = {
  width: "300px",
  marginTop: "90px",
  marginBottom: "50px",
  height: "50px",
};

const wppIconContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  backgroundColor: "#0c0c1c",
  position: "fixed",
  bottom: "20px",
  right: "20px",
  zIndex: 999,
  backgroundColor: "transparent",
};

const wppIconStyles = {
  width: "8vh",
  height: "8vh",
  marginRight: "10px",
};

const muxPlayerStyles = `
  mux-player {
    --dialog: none;
    --loading-indicator: none;
    --live-button: none;
    --seek-backward-button: none;
    --seek-forward-button: none;
    --mute-button: none;
    --captions-button: none;
    --airplay-button: none;
    --pip-button: none;
    --fullscreen-button: none;
    --cast-button: none;
    --playback-rate-button: none;
    --volume-range: none;
    --time-range: none;
    --time-display: none;
    --duration-display: none;
    --rendition-selectmenu: none;
    --bottom-play-button: none;
    border-radius: 20px;
    width: 100%;
  }
`;

export default function VslPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [refer, setRefer] = useState("");
  const [logged, setLogged] = useState(false);
  const [buttonColor, setButtonColor] = useState("gray");
  const [buttonClickable, setButtonClickable] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const videoRef = useRef(null);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setRefer(params.get("refer") || "");
  }, []);

  const handleButtonClick = () => {
    window.location.href = `/register?refer=${refer}`;
  };

  useEffect(() => {
    const checkTime = () => {
      if (videoRef.current && !logged) {
        const currentTime = videoRef.current.currentTime;
        if (currentTime >= 5) {
          console.log("5 seconds have elapsed!");
          setLogged(true);
          setButtonColor("red");
          setButtonClickable(true);
          return true;
        }
      }
      return false;
    };

    const intervalId = setInterval(() => {
      const shouldStop = checkTime();
      if (shouldStop) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [logged]);

  const onReady = () => {
    console.log("Video is ready");
    videoRef.current.play().catch((error) => {
      console.error("Autoplay failed:", error);
    });
  };

  return (
    <Fragment>
      <style>{muxPlayerStyles}</style>
      <Layout>
        <GlobalStyles />
        <div style={{ ...bannerStyles, backgroundImage: `url(${bannerIcon})` }}>
          <h1
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            AUMENTE SEUS GANHOS COM APOSTAS
          </h1>
          <h5 style={{ textAlign: "center", marginBottom: "40px" }}>
            Receba as recomendações mais lucrativas do mundo e acesse filtros,
            rankings e gráficos de análise profissionais!
          </h5>
          <div
            style={{
              width: "100%",
              maxWidth: "1200px",
              maxHeight: "500px",
              margin: "40px",
            }}
          >
            <MuxPlayer
              streamType="on-demand"
              nohotkeys
              playbackId="KQfnW01AhfFjMF6XrATHRFmCrlcvuGqkiMPjM4jcmIQE"
              metadataVideoTitle="Placeholder (optional)"
              metadataViewerUserId="Placeholder (optional)"
              primaryColor="#FF6666"
              secondaryColor="#000000"
              ref={videoRef}
              onReady={onReady}
              controls={true}
              poster="https://image.mux.com/KQfnW01AhfFjMF6XrATHRFmCrlcvuGqkiMPjM4jcmIQE/animated.gif?width=320"
            />
          </div>
        </div>
      </Layout>
      <div style={wppIconContainerStyles}>
        <a
          href="https://seu-link-do-whatsapp-aqui"
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            boxShadow: isHovered ? "0px 0px 5px 0px rgba(0,0,0,0.75)" : "none",
          }}
        >
          <img
            src={isHovered ? wppIcon2 : wppIcon1}
            alt="WhatsApp"
            style={wppIconStyles}
          />
        </a>
      </div>
      <div style={buttonContainerStyles}>
        <Button
          style={{
            ...buttonStyles,
            backgroundColor: buttonColor,
            pointerEvents: buttonClickable ? "auto" : "none",
            cursor: buttonClickable ? "pointer" : "default",
            opacity: buttonClickable ? 1 : 0.5,
            marginTop: window.innerWidth < 1000 ? "-30px" : "90px",
            marginTop: window.innerWidth < 800 ? "-60px" : "90px",
          }}
          onClick={handleButtonClick}
          disabled={!buttonClickable}
        >
          QUERO LUCRAR COM A ClashFy
        </Button>
      </div>
      <div>
        <div
          style={{
            ...bannerStyles,
            backgroundImage: `url(${bannerIcon2})`,
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: "80%",
              margin: "0 auto",
              maxWidth: window.innerWidth < 1250 ? "100%" : "auto",
            }}
          >
            <h1
              style={{
                fontSize: "35px",
                textAlign: "center",
                marginBottom: "70px",
              }}
            >
              VEJA O QUE VOCÊ<br></br>
              <span style={{ color: "red" }}>VAI RECEBER EM NOSSO PACK</span>
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "0 auto",
                flexDirection: window.innerWidth < 1250 ? "column" : "row",
                width: window.innerWidth < 1250 ? "100%" : "70%",
                padding: "30px",
              }}
            >
              <div
                style={{
                  marginBottom: "10px",
                  maxWidth: "50%",
                  marginTop: window.innerWidth < 1250 ? "-50px" : "0",
                  textAlign: window.innerWidth < 1100 ? "center" : "left",
                  maxWidth: window.innerWidth < 1250 ? "100%" : "auto",
                  width: window.innerWidth < 1250 ? "100%" : "auto",
                }}
              >
                <h5 style={{ marginBottom: "30px" }}>
                  Entregamos 25 modelos de criativos que dão os melhores
                  resultados aqui na minha empresa. Alto CRT e ROAS em nossas
                  campanhas!
                </h5>
                <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
                  <li>
                    <img
                      style={{ width: "15px", marginRight: "3px" }}
                      src={clashIcon}
                      alt="Ícone"
                    />
                    Você pode alterar o logotipo com o nome da sua escolha
                  </li>
                  <li>
                    <img
                      style={{ width: "15px", marginRight: "3px" }}
                      src={clashIcon}
                      alt="Ícone"
                    />
                    Pode alterar as cores dos criativos e das páginas
                  </li>
                  <li>
                    <img
                      style={{ width: "15px", marginRight: "3px" }}
                      src={clashIcon}
                      alt="Ícone"
                    />
                    Todos os projetos foram testados e validados
                  </li>
                  <li>
                    <img
                      style={{ width: "15px", marginRight: "3px" }}
                      src={clashIcon}
                      alt="Ícone"
                    />
                    Faça quantas combinações quiser com apenas 1 criativo
                  </li>
                  <li>
                    <img
                      style={{ width: "15px", marginRight: "3px" }}
                      src={clashIcon}
                      alt="Ícone"
                    />
                    Projetos editáveis no photoshop
                  </li>
                  <li>
                    <img
                      style={{ width: "15px", marginRight: "3px" }}
                      src={clashIcon}
                      alt="Ícone"
                    />
                    Projetos editáveis no canva totalmente grátis
                  </li>
                  <li>
                    <img
                      style={{ width: "15px", marginRight: "3px" }}
                      src={clashIcon}
                      alt="Ícone"
                    />
                    Páginas com efeitos de movimento CSS já inclusos
                  </li>
                  <li>
                    <img
                      style={{ width: "15px", marginRight: "3px" }}
                      src={clashIcon}
                      alt="Ícone"
                    />
                    Páginas separadas por sessões facilmente editáveis
                  </li>
                  <li>
                    <img
                      style={{ width: "15px", marginRight: "3px" }}
                      src={clashIcon}
                      alt="Ícone"
                    />
                    Suporte exclusivo e especializado
                  </li>
                </ul>
                <h4 style={{ textAlign: "center", marginTop: "30px" }}>
                  O MELHOR PACK DE IGAMING DO BRASIL
                </h4>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  maxWidth: "500px",
                  height: "450px",
                  marginBottom: "20px",
                  borderRadius: "10px",
                  marginLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  boxShadow: "0px 0px 10px 5px rgba(255, 0, 0, 0.5)",
                }}
              >
                <img
                  src={clashIcon}
                  alt="clashicon"
                  style={{ width: "30%", margin: "10px" }}
                />
                <h1
                  style={{
                    color: "red",
                    textAlign: "center",
                    lineHeight: "200px",
                    margin: 0,
                    fontSize: "40px",
                  }}
                >
                  de R$ 497,00
                </h1>
                <h3
                  style={{
                    color: "blue",
                    textAlign: "center",
                    lineHeight: "200px",
                    margin: 0,
                  }}
                >
                  POR APENAS 12x de R$ 25,19
                </h3>
                <h1
                  style={{
                    color: "black",
                    textAlign: "center",
                    lineHeight: "200px",
                    margin: 0,
                  }}
                >
                  OU R$ 247,00 À VISTA
                </h1>
                <Button
                  style={{
                    backgroundColor: "red",
                    marginTop: "20px",
                    minHeight: "30px",
                    width: window.innerWidth < 400 ? "190px" : "300px",
                  }}
                >
                  Comprar Agora
                </Button>
                <p style={{ fontSize: "10px", color: "black" }}>
                  Pagamento Único - Sem mensalidade
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "black",
                    marginTop: "-20px",
                    fontWeight: "bold",
                  }}
                >
                  Acesso Vitalício
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
